import React, { useCallback, useEffect, useMemo, useRef, useState, VFC } from 'react';
import { once } from 'lodash';
import { findDOMNode } from 'react-dom';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Text } from 'wix-ui-tpa/cssVars';
import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { PaymentsWidget, PaymentsWidgetAPI, PaymentsWidgetProps } from '@wix/cashier-payments-widget/lazy';
import { useEnvironment, useFedopsLogger, useTranslation } from '@wix/yoshi-flow-editor';
import { CheckoutProps, UserData } from '../../../../../types/common';
import { PackagePickerInteractions } from '../../../../../types/PackagePickerFedops';
import { CheckoutStyledButton } from '../CheckoutStyledButton';
import { StepSection } from '../StepSection';
import { StepAPI, StepProps } from '../types';
import { classes } from './PaymentStep.st.css';

interface PaymentStepProps extends StepProps {
  plan: PublicPlan;
  user: UserData;
  userEmail?: string;
  appInstanceId: string;
  instance?: string;
  onLoad: () => void;
  onApiInit: PaymentsWidgetProps['onApiInit'];
  onFullLoad: PaymentsWidgetProps['onFullLoad'];
  paymentMethodChanged: PaymentsWidgetProps['paymentMethodChanged'];
  order?: Order;
  shouldUpgrade?: boolean;
  cashierApi?: PaymentsWidgetAPI;
  viewMode: string;
  metaSiteId?: string;
  siteOwnerId?: string;
  visitorId?: string;
  siteStyles: any;
  guestEmail: CheckoutProps['guestEmail'];
}

export const PaymentStep: VFC<PaymentStepProps> = ({
  position,
  onApiSet,
  onCompleted,
  isLastStep,
  isNarrow,
  state: stepState,
  plan,
  onLoad,
  user,
  userEmail,
  order,
  shouldUpgrade,
  onApiInit,
  onFullLoad,
  paymentMethodChanged,
  appInstanceId,
  instance,
  viewMode,
  metaSiteId,
  siteOwnerId,
  visitorId,
  siteStyles,
  guestEmail,
  cashierApi,
}) => {
  const fedops = useFedopsLogger();
  const { appDefinitionId, language, isPreview } = useEnvironment();
  const { t } = useTranslation();

  const enabled = user.loggedIn ? Boolean(order) || Boolean(shouldUpgrade) || isPreview : Boolean(order);
  const state = !enabled ? 'pending' : stepState;

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const validationResolveRef = useRef<(() => void) | null>(null);
  const isDemoFlow = isPreview || (shouldUpgrade && user.role === 'Admin');

  const validate = useCallback(async () => {
    if (cashierApi) {
      setIsValidating(true);
      const promise = new Promise<void>((resolve) => {
        validationResolveRef.current = resolve;
      });
      const result = await cashierApi.validate();
      setIsValid(result.isValid);
      setIsValidating(false);
      if (result.isValid) {
        onCompleted();
      }
      // delaying validate execution until isValid has been updated
      return promise;
    }
  }, [cashierApi, setIsValid, setIsValidating, onCompleted]);

  useEffect(() => {
    if (!isValidating) {
      validationResolveRef.current?.();
    }
  }, [isValidating]);

  useEffect(() => {
    if (state === 'active' && !initialized) {
      setInitialized(true);
      onLoad();
    }
    if (state === 'active') {
      if (paymentSectionRef.current) {
        try {
          const node = findDOMNode(paymentSectionRef.current) as Element;
          smoothScrollIntoView(node);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [state]);

  const configuration: PaymentsWidgetProps['configuration'] = useMemo(() => {
    const { value = '', currency = '' } = plan.pricing?.price ?? {};
    return {
      locale: language,
      appId: appDefinitionId,
      appInstanceId,
      appInstance: instance,
      viewMode: viewMode === 'Site' ? 'Site' : 'Preview',
      isSignedInUser: user.loggedIn,
      amount: value,
      currency,
      msid: metaSiteId ?? '',
      snapshotId: order?.wixPayOrderId!,
      siteOwnerId,
      visitorId,
    };
  }, [
    language,
    appDefinitionId,
    appInstanceId,
    instance,
    viewMode,
    user.loggedIn,
    plan,
    metaSiteId,
    order,
    siteOwnerId,
    visitorId,
  ]);

  const api: StepAPI = useMemo(
    () => ({
      execute: async () => {
        await validate();
      },
      isValid: () => isValid,
    }),
    [validate, isValid],
  );

  useEffect(() => {
    onApiSet(api);
  }, [api]);

  const showContactMessage = shouldUpgrade && user.role !== 'Admin';

  useEffect(() => {
    if (!showContactMessage) {
      fedops.interactionStarted(PackagePickerInteractions.CashierWidgetLoaded);
    }
  }, []);

  const cashierWidgetLoaded = useCallback(
    once(() => fedops.interactionEnded(PackagePickerInteractions.CashierWidgetLoaded)),
    [],
  );

  return (
    <StepSection
      ref={paymentSectionRef}
      dataHook="payment-cashier-section"
      title={t('payment.checkout-steps.payment.title')}
      position={position}
      state={state}
    >
      <div className={classes.root}>
        {showContactMessage ? (
          <ContactToPurchaseMessage />
        ) : (
          <>
            {configuration.snapshotId || isDemoFlow ? (
              <div data-hook="payment-cashier-container-v2">
                <PaymentsWidget
                  isSaveCCEnabled
                  externalSubmitButton
                  onApiInit={onApiInit}
                  onFullLoad={() => {
                    cashierWidgetLoaded();
                    onFullLoad?.();
                  }}
                  configuration={configuration}
                  siteStyles={siteStyles}
                  paymentMethodChanged={paymentMethodChanged}
                  mandatoryFieldsPrefilled={{ email: userEmail ?? guestEmail }}
                />
              </div>
            ) : null}
            {!isLastStep || isNarrow ? (
              <CheckoutStyledButton
                className={classes.button}
                onClick={validate}
                disabled={!enabled || isValidating}
                label={t('payment.checkout-steps.payment.continue-button')}
              />
            ) : null}
          </>
        )}
      </div>
    </StepSection>
  );
};

const ContactToPurchaseMessage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div data-hook="premium-checkout-blocked-message">
      <Text tagName="h2" className={classes.blockedCheckoutTitle}>
        {t('payment.premium.checkout-blocked-title')}
      </Text>
      <Text tagName="p" className={classes.blockedCheckoutText}>
        {t('payment.premium.checkout-blocked-body')}
      </Text>
    </div>
  );
};
